import Image from "next/image";
import * as PropTypes from "prop-types";

import CTAButton from "~/components/CTAButton";
import RichText from "~/components/RichText";

const CourseListingItem = function ({ data, theme }) {
  return (
    <div className={"rte course-listing-item relative " + theme}>
      {data.image && data.image.url && (
        <Image
          className="course-listing-image"
          src={data.image.url}
          alt={data.image.alt || ""}
          quality={50}
          loading="lazy"
          sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
          width={data.image.dimensions.width}
          height={data.image.dimensions.height}
        />
      )}

      {data.course_category && (
        <p className="course-listing-category">{data.course_category}</p>
      )}

      {data.course_tagline && (
        <p className="course-listing-tagline">{data.course_tagline}</p>
      )}

      {data.course_title && (
        <p className="course-listing-title">{data.course_title}</p>
      )}

      <div className="rte course-listing-body">
        {data.body && <RichText field={data.body} />}
      </div>

      <CTAButton className={`course-listing-link ${data.cta_classes ? data.cta_classes : ""}`} field={data} />
    </div>
  );
};

CourseListingItem.propTypes = {
  data: PropTypes.object.isRequired,
  theme: PropTypes.string,
};

export default CourseListingItem;
